"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DashboardMobileTemplate = void 0;
const __1 = require("../..");
const react_1 = __importStar(require("react"));
const DashboardMobileTemplate_styles_1 = require("./DashboardMobileTemplate.styles");
const DashboardMobileTemplate = ({ bodyPadding, children, headerActions, logo, menu, onLeftMenuOpen, shouldChildrenScollUnderHeader, }) => {
    const classes = (0, DashboardMobileTemplate_styles_1.useStyles)();
    const accordionClasses = (0, DashboardMobileTemplate_styles_1.useAccordionStyles)();
    const [isLeftTextMenuOpen, setIsLeftTextMenuOpen] = (0, react_1.useState)(false);
    const handleLeftTextMenuOpen = (0, react_1.useCallback)(() => {
        if (onLeftMenuOpen)
            onLeftMenuOpen(true);
        setIsLeftTextMenuOpen(true);
    }, [onLeftMenuOpen, setIsLeftTextMenuOpen]);
    const [sortedMenu, sortMenuItems] = (0, react_1.useMemo)(() => {
        if (!menu)
            return [[], {}];
        const menuItems = {};
        const sortedMenu = Object.keys(menu)
            .filter((key) => !menu[key].maintenanceIcon && menu[key].permissions)
            .sort((a, b) => menu[a].order - menu[b].order)
            .map((key) => {
            const links = Object.values(menu[key].links).sort((a, b) => {
                return a.order - b.order;
            });
            menuItems[menu[key].name] = links;
            return {
                hasNotification: Boolean(menu[key].hasNotification),
                icon: menu[key].icon,
                name: menu[key].name,
                title: menu[key].title,
            };
        });
        return [sortedMenu, menuItems];
    }, [menu]);
    return (react_1.default.createElement(__1.Grid, { container: true, direction: "column" },
        react_1.default.createElement(__1.Grid, { alignItems: "center", container: true, item: true, justifyContent: "center", style: { background: '#061946', height: 80, position: 'fixed', top: 0, zIndex: 2 } },
            logo,
            Boolean(menu) && (react_1.default.createElement("button", { onClick: handleLeftTextMenuOpen, style: {
                    background: 'transparent',
                    border: 0,
                    color: '#fff',
                    cursor: 'pointer',
                    left: 24,
                    position: 'absolute',
                    top: 24,
                    zIndex: 1,
                } },
                react_1.default.createElement(__1.MenuIcon, null))),
            headerActions),
        react_1.default.createElement(__1.Grid, { item: true, style: {
                height: '100%',
                marginTop: '80px',
                overflowX: 'hidden',
                position: 'relative',
                width: '100%',
                zIndex: shouldChildrenScollUnderHeader ? 1 : 3,
            } },
            react_1.default.createElement(__1.Box, { p: bodyPadding !== undefined ? bodyPadding : 3 }, children)),
        react_1.default.createElement(__1.SwipeableDrawer, { ModalProps: { BackdropProps: { invisible: true } }, PaperProps: { className: classes.popover }, anchor: 'left', onClose: () => setIsLeftTextMenuOpen(false), onOpen: handleLeftTextMenuOpen, open: isLeftTextMenuOpen },
            react_1.default.createElement(__1.Button, { onClick: () => setIsLeftTextMenuOpen(false), style: { color: 'white', position: 'absolute', right: 24, top: 24 } },
                react_1.default.createElement(__1.CloseIcon, null)),
            react_1.default.createElement(__1.Grid, { container: true, direction: "column" },
                react_1.default.createElement(__1.Grid, { alignItems: "center", container: true, item: true, justifyContent: "center" },
                    react_1.default.createElement(__1.Box, { mt: 3 }, logo)),
                react_1.default.createElement(__1.Grid, { item: true },
                    react_1.default.createElement(__1.Box, { pt: 3 }, sortedMenu.map((item) => {
                        return (react_1.default.createElement(__1.Grid, { container: true, item: true, justifyContent: "center", key: item.name },
                            react_1.default.createElement(__1.Accordion, { classes: accordionClasses },
                                react_1.default.createElement(__1.AccordionSummary, { expandIcon: react_1.default.createElement(__1.ExpandMoreIcon, { style: { color: '#fff' } }) },
                                    react_1.default.createElement(__1.Grid, { container: true, item: true, justifyContent: "center" },
                                        react_1.default.createElement(__1.Typography, { color: "white", fontStyle: "semibold", variant: "bodyLarge" }, item.title))),
                                react_1.default.createElement(__1.AccordionDetails, { style: { background: '#fff' } },
                                    react_1.default.createElement(__1.Grid, { container: true, direction: "column" }, sortMenuItems[item.name].map((link, index) => {
                                        return (react_1.default.createElement(__1.Grid, { item: true, key: index }, link.link));
                                    }))))));
                    })))))));
};
exports.DashboardMobileTemplate = DashboardMobileTemplate;
